<template>
  <div style="
                    display: flex;
                    margin-top: 30px;
                    position: relative;

                    height: 260px;
                  ">
    <div class="custom-legend" style="margin-right: 100px">
      <div style="display: flex; margin-top: 12px; margin-bottom: 10px">
        <div class="select-title">周期筛选</div>
        <div class="select-container">
          <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
          <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
          <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 12px; margin-bottom: 10px">
        <div class="select-title">指标筛选</div>
        <div class="select-container">
          <div class="select-option" :class="changeNum == 1 ? 'active' : ''" @click="changeNumer('1')">用户量</div>
          <div class="select-option" :class="changeNum == 2 ? 'active' : ''" @click="changeNumer('2')">用户占比</div>
        </div>
      </div>
    </div>

    <div ref="chart" style="width: 525px; height: 300px; margin-left: 40px; margin-top: -60px"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import _ from "lodash";
export default {
  data() {
    return {
      changeSelect: '3',
      changeNum: '1',
      selectedOption: "",
      legendData: [
        { name: "访问人数", color: "#2DB7F5", active: true },
        { name: "打开次数", color: "#808BC6", active: true },
        { name: "访问页面数", color: "#F9BF00", active: true },
      ],
      originSeries: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
      series: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
    };
  },
  mounted() {
    this.getData()
    // this.renderChart();
  },
  methods: {
    changeTime(value) {
      this.changeSelect = value
      this.getData()
    },
    changeNumer(value) {
      this.changeNum = value
      this.getData()
    },
    async getData() {
      let data = {
        timeType: this.changeSelect,
        accessType: this.changeNum,
      }
      let res = await this.$http.post('/memberVisitDayStatistics/userAgeGroupStatistics', data)
      if (res.data.code == 200) {
        this.tableData = res.data.data
        this.renderChart()
      }
    },
    renderChart() {
      let seriesList = this.tableData.map(item => {
        return item.num
      })
      let xAxisList = this.tableData.map(item => {
        return item.ageGroup
      })
      const chart = echarts.init(this.$refs.chart);

      const option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["分享/单即分享/全部", "搜索/手机搜索/全部"],
          left: "70%", // 将图例放在中间的左边
          top: "top", // 将图例放在上面
        },
        grid: {
          left: "30%", // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: "70%", // 设置图表宽度为整个容器宽度的80%
        },
        xAxis: {
          type: "category",
          data: xAxisList,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // name: "分享/单即分享/全部",
            type: "line",
            smooth: true,
            data: seriesList,
            lineStyle: {
              color: "#2DB7F5",
            },
            itemStyle: {
              color: "#2DB7F5",
            },
            areaStyle: {
              color: "#D5F0FD",
            },
          },
        ],
      };

      chart.setOption(option);
    },
    toggleSeries(selectItem) {
      //    改变class
      this.legendData.forEach((item) => {
        item.active = true;
      });
      selectItem.active = false;

      const chart = echarts.init(this.$refs.chart);
      const clonedOriginSeries = _.cloneDeep(this.originSeries);
      // 改变某一个值的可见性
      this.series.forEach((item) => {
        if (item.name === selectItem.name) {
          item.visible = false; // 取反visible属性
        }
      });
      this.series.forEach((item) => {
        if (!item.visible) {
          item.data = [];
        }
      });
      const option = chart.getOption();
      console.log({ ...option, series: this.series });
      chart.setOption({ ...option, series: this.series });
      this.series = clonedOriginSeries;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.select-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 3px 10px;
    margin-bottom: 4px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.custom-legend {
  position: absolute;
  z-index: 111;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}

/deep/ .el-input__inner {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}
</style>