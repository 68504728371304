<template>
  <div style="position: relative;">
    <div style="display: flex; margin-bottom: 20px;">
      <div class="select-title">周期筛选</div>
      <div class="select-container">
        <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
        <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
        <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
      </div>
    </div>
    <!-- <div style="display: flex; margin-top: 5px">
      <div class="select-title">指标筛选</div>
      <div class="select-container">
        <div class="select-option" :class="changeNum == 1 ? 'active' : ''" @click="changeNumer('1')">访问人数</div>
        <div class="select-option" :class="changeNum == 2 ? 'active' : ''" @click="changeNumer('2')">打开次数</div>
      </div>
    </div> -->
    <div ref="chart" style="width:300px; height: 200px;"></div>
    <div style="position: absolute;top:110px;left:90px;z-index: 11111;">
      <div style="font-size: 30px; text-align: center;">{{ allData }}</div>
      <div style="color: #c0c0c0;font-size: 14px;margin-left: 4px;">总和</div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';

export default {
  data() {
    return {
      changeSelect: '3',
      changeNum: '1',
      allData: ''
    }
  },
  mounted() {
    this.getAllData()
  },
  methods: {
    changeTime(value) {
      this.changeSelect = value
      this.getAllData()
    },
    changeNumer(value) {
      this.changeNum = value
      this.getAllData()
    },
    async getAllData() {
      let data = {
        timeType: this.changeSelect,
        accessType: this.changeNum
      }
      let res = await this.$http.post('/memberVisitDayStatistics/userSexStatistics', data)
      if (res.data.code == 200) {
        let data = res.data.data
        this.dataList = data
        let allData = data.map(item => {
          return item.num
        })
        this.allData = allData.reduce((a, b) => {
          return a + b;
        })
        this.renderChart();
      }
    },
    renderChart() {
      let seriesData = this.dataList.map(item => {
        item.name = item.sex
        item.value = item.num
        return item
      })
      let dataName = this.dataList.map(item => {
        return item.name
      })
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: '70%', // 将图例往右移动
          bottom: '10%',
          data: dataName,
        },
        series: [
          {
            name: '用户数量',
            type: 'pie',
            radius: ['70%', '90%'],
            center: ['35%', '50%'], // 将环图往左移动
            label: {
              show: false // 隐藏标注
            },
            color: ['#2DB7F5', '#7DC856', '#F9BF00', '#808BC6', '#7180CE', '#FAC958'],
            // data: [
            //   { value: 100, name: 'Android', itemStyle: { color: '#2DB7F5' } },
            //   { value: 200, name: 'iPhone', itemStyle: { color: '#7DC856' } },
            //   { value: 150, name: 'Windows PC', itemStyle: { color: '#F9BF00' } },
            //   { value: 120, name: 'macOS', itemStyle: { color: '#808BC6' } },
            // ],
            data: seriesData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },

          },
        ],
        //   graphic: [
        //   {
        //     type: 'text',
        //     left: '30%',
        //     top: 'middle',
        //     style: {
        //       text: '84\n总和',
        //       textAlign: 'center',
        //       font: '20px Arial',     
        //     },
        //   },
        // ],
      };
      chart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
}

.select-container {
  display: flex;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}
</style>