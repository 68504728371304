<template>
  <div>
    <div>
      <div class="select-container">
        <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">访问人数</div>
        <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">打开次数</div>
        <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">访问页面数</div>
      </div>
    </div>
    <div ref="chart" style="width:280px; height: 210px;margin-left: 100px;position: absolute;top: -66px; "></div>
  </div>
</template>

<script>
import echarts from 'echarts';

export default {
  data() {
    return {
      listData: {},
      dataList: [],
      changeSelect: '1',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    init(data) {
      this.listData = data
      // this.renderChart();
    },
    changeTime(value) {
      this.changeSelect = value
      this.getData()
    },
    async getData() {
      let data = {
        accessType: this.changeSelect,
      }
      let res = await this.$http.post('/memberVisitDayStatistics/accessIndicators', data)
      if (res.data.code == 200) {
          if (res.data.data.resultList) {
          this.dataList = res.data.data.resultList;
          console.log(this.dataList);
          this.average = res.data.data.average;
          this.renderChart();
        }
        // this.dataList = res.data.data.resultList
        // this.renderChart();
      }
    },

    renderChart() {
      let xAxisList = this.dataList.map(item => {
        return item.day || ''
      })
      let seriesList = this.dataList.map(item => {
        return item.count
      })
      const chart = echarts.init(this.$refs.chart);

      const option = {
        //   title: {
        //     text: '访问统计',
        //   },
        tooltip: {
          trigger: 'axis',
        },
        // legend: {
        //   data: ['访问人数', '打开次数', '访问页面数'],
        //   orient: 'vertical', // 设置图例垂直排列
        //   left: 'left', // 将图例放在中间的左边
        //   top: 'center', // 将图例放在上面
        // },
        grid: {
          left: '10%', // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: '80%', // 设置图表宽度为整个容器宽度的80%

        },

        xAxis: {
          name: '时',
          nameTextStyle: {
            padding: [10, 0, -20, -10],
          },
          type: 'category',
          data: xAxisList,
        },
        yAxis: {
          name: this.changeSelect == 1 ? '人' : this.changeSelect == 2 ? '次' : this.changeSelect == 3 ? '页面' : '',
          nameTextStyle: {
            padding: [10, 20, -5, 0],
          },

          type: 'value',
          // splitLine: {
          //   show: false
          // }
        },
        series: [
          {
            name: '访问人数',
            type: 'line',
            data: seriesList,
            itemStyle: {
              color: '#2DB7F5',
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.select-container {
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    margin-top: 5px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}


.custom-legend {
  position: absolute;
  z-index: 111;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}
</style>
