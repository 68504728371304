<template>
  <div>
    <div style="display: flex; margin-top: 12px;margin-bottom:15px;width: 440px;">
      <div class="select-title">周期筛选</div>
    <div class="select-container">
      <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
      <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
      <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
    </div>
  </div>
  <div style="height: 100px; overflow: auto;">
    <div v-for="item, index in dataList" :key="index">
      <div class="progress-container">
        <div class="progress-title">{{ item.modelType }}</div>
        <div class="progress-content">{{ item.proportion + '|' + item.count }}</div>
      </div>
      <el-progress :percentage="item.rateNum" :show-text="false"></el-progress>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
  return {
    changeSelect: '3',
    changeNum: '1',
    dataList: []
  }
},
mounted() {
  this.getAllData()
},
methods: {
  changeTime(value) {
    this.changeSelect = value
    this.getAllData()
  },
  changeNumer(value) {
    this.changeNum = value
    this.getAllData()
  },
  async getAllData() {
    let data = {
      timeType: this.changeSelect,
      accessType: this.changeNum,
      systemType: 'IOS'
    }
    let res = await this.$http.post('/memberVisitDayStatistics/systemTop10', data)
    if (res.data.code == 200) {
      let data = res.data.data
      this.dataList = data.map(item => {
        item.rateNum = Number(item.proportion.split('').slice(0, -1).join(''))
        return item
      })

    }
  }
},
};
</script>

<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
}
.select-container {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;
    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.progress-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .progress-title {
    margin-top: 10px;
  }
  .progress-content {
    color: #c0c0c0;
    margin: 7px 0 7px;
  }
}
</style>