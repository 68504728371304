<template>
    <div style="display: flex;margin-top: 30px; position: relative;width: 670px;height: 230px;">
        <div class="mainBox1">
            <div class="title">
                <div class="right" style="display: flex; align-items: center;">
                    <span class="select-title" style="font-size:15px">时间筛选：</span>
                    <el-date-picker v-model="date1" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <el-button @click="getNewuserstrend" style="margin-left:4px" type="primary">查询</el-button>
                </div>
            </div>
        </div>
        <div ref="chart" style="
        width: 100%;
        height: 210px;
        margin-left: 10px;
        position: absolute;
        margin-top: 30px
      " class="main1" id="main1"></div>
    </div>
</template>

<script>
import echarts from "echarts";
import _ from "lodash";
export default {
    data() {
        return {
            date1: '',
            changeSelect: '3',
            selectedOption: "",
            time: '',
            dataList: [],
        };
    },
    mounted() {
        this.getNewuserstrend()
    },
    methods: {
        async getNewuserstrend() {
            let data = { startTime: "", endTime: "" }
            if (Array.isArray(this.date1) && this.date1.length > 0) {
                data.startTime = this.date1[0] + ' 00:00:00'
                data.endTime = this.date1[1] + ' 23:59:59'
            }
            let res = await this.$http.post('fair/fairPublistDay', data)
            let date = []  //日期
            let processList = [] //审核中
            let passList = []
            let refuseList = []
            let allList = []
            if (res.data.code == 200) {
                res.data.data.forEach(item => {
                    this.dataList = res.data.data
                    date.push(item.createDt)
                    processList.push(item.processNum)
                    passList.push(item.passNum)
                    refuseList.push(item.refuseNum)
                    allList.push(item.allNum)
                })
            }
            this.renderChart()
        },
        renderChart() {
            let xAxisList = this.dataList.map(item => {
                return item.createDt
            })
            let seriesList1 = this.dataList.map(item => {
                return item.allNum
            })
            let seriesList2 = this.dataList.map(item => {
                return item.processNum
            })
            let seriesList3 = this.dataList.map(item => {
                return item.passNum
            })
            let seriesList4 = this.dataList.map(item => {
                return item.refuseNum
            })
            const chart = echarts.init(this.$refs.chart);
            
            const option = {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    show: true, // 隐藏原有图例
                    data: ["发布市集", "审核中", "审核通过", "已拒绝"],
                    orient: "vertical", // 设置图例垂直排列
                    left: "left", // 将图例放在中间的左边
                    top: "center", // 将图例放在上面
                },
                grid: {
                    left: "20%", // 将图表整体向右偏移 20%，实现标注在中间左边的效果
                    width: "70%", // 设置图表宽度为整个容器宽度的80%
                },

                xAxis: {
                    type: "category",
                    data: xAxisList,
                    name: '天',
                    nameTextStyle: {
                        padding: [10, 0, -20, -10],
                    }
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: '发布市集',
                        type: 'line',
                        yAxisIndex: 0,
                        itemStyle: {
                            color: '#02A7F0'
                        },

                        type: "line",
                        smooth: true,
                        data: seriesList1,
                    },
                    {
                        name: '审核中',
                        type: 'line',
                        yAxisIndex: 0,
                        itemStyle: {
                            color: '#f59a23'
                        },

                        type: "line",
                        smooth: true,
                        data: seriesList2,
                    },
                    {
                        name: '审核通过',
                        type: 'line',
                        yAxisIndex: 0,
                        itemStyle: {
                            color: '#63a103'
                        },

                        type: "line",
                        smooth: true,
                        data: seriesList3,
                    },
                    {
                        name: '已拒绝',
                        type: 'line',
                        yAxisIndex: 0,
                        itemStyle: {
                            color: '#ec808d'
                        },
                        smooth: true,
                        data: seriesList4,
                    },
                ],
            };
            chart.setOption(option);
        },

    },
};
</script>
<style lang="scss" scoped>
.select-title {
    color: #c0c0c0;
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
}

.select-container {
    display: flex;
    font-size: 12px;
    margin-left: 10px;

    .select-option {
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        padding: 0 5px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
        text-align: center;

        &:hover,
        &.active {
            background-color: #f0f0f0;
            cursor: pointer;
        }
    }
}

.custom-legend {
    position: absolute;
    z-index: 111;
}

.legend-item {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 5px 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
    text-align: center;
    background-color: #fff;

    &.active {
        background-color: #f0f0f0;
        cursor: pointer;
    }
}

.legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

.legend-label {
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
}

// /deep/ .el-input__inner {
//     background: #e9e9e9;
//     border-color: #c0c0c0 !important;
//     outline: #c0c0c0 !important;
//     font-size: 12px;
// }</style>