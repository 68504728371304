import { render, staticRenderFns } from "./UserAgeTrend.vue?vue&type=template&id=475f3bcc&scoped=true"
import script from "./UserAgeTrend.vue?vue&type=script&lang=js"
export * from "./UserAgeTrend.vue?vue&type=script&lang=js"
import style0 from "./UserAgeTrend.vue?vue&type=style&index=0&id=475f3bcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475f3bcc",
  null
  
)

component.options.__file = "UserAgeTrend.vue"
export default component.exports