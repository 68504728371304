import { render, staticRenderFns } from "./publishShortVideos.vue?vue&type=template&id=47183b41&scoped=true"
import script from "./publishShortVideos.vue?vue&type=script&lang=js"
export * from "./publishShortVideos.vue?vue&type=script&lang=js"
import style0 from "./publishShortVideos.vue?vue&type=style&index=0&id=47183b41&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47183b41",
  null
  
)

component.options.__file = "publishShortVideos.vue"
export default component.exports