import { render, staticRenderFns } from "./RightNowPageTopVistor.vue?vue&type=template&id=61f95d18&scoped=true"
import script from "./RightNowPageTopVistor.vue?vue&type=script&lang=js"
export * from "./RightNowPageTopVistor.vue?vue&type=script&lang=js"
import style0 from "./RightNowPageTopVistor.vue?vue&type=style&index=0&id=61f95d18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f95d18",
  null
  
)

component.options.__file = "RightNowPageTopVistor.vue"
export default component.exports