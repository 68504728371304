<template>
  <div style="display: flex;margin-top: 30px; position: relative;width: 670px;height: 230px;">
    <div class="custom-legend" style="margin-right: 100px">
      <div style="display: flex; margin-top: 12px; margin-bottom: 10px">
        <div class="select-title">周期筛选</div>
        <div class="select-container">
          <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
          <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
          <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
        </div>
      </div>
      <div style="display: flex">
        <div class="select-title">指标筛选</div>
        <el-select v-model="selectedOption" placeholder="请选择" style="width: 150px; outline: none">
          <el-option label="分享/单即分享/全部" value="share"></el-option>
          <el-option label="搜索/手机搜索/全部" value="singleShare"></el-option>
        </el-select>
        <div>
          <!-- <div
              class="legend-item"
              v-for="item in legendData"
              :key="item.name"
              @click="toggleSeries(item)"
              :class="{ 'active': item.active }"
            >
              <div
                class="legend-icon"
                :style="{ backgroundColor: item.color }"
              ></div>
              <div class="legend-text">{{ item.name }}</div>
            </div> -->
        </div>
      </div>
    </div>
    <div ref="chart" style="width: 520px; height: 300px; margin-left: 240px; margin-top: -60px;"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import _ from "lodash";
export default {
  data() {
    return {
      changeSelect: '3',
      selectedOption: "",
      legendData: [
        { name: "访问人数", color: "#2DB7F5", active: true },
        { name: "打开次数", color: "#808BC6", active: true },
        { name: "访问页面数", color: "#F9BF00", active: true },
      ],
      originSeries: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
      series: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
      time: '',
    };
  },
  mounted() {
    this.getNewuserstrend()
  },
  methods: {
    init(time) {
      this.time = time
      this.getNewuserstrend()
    },
    async getNewuserstrend() {
      let data = {
        startstime: this.time[0],
        endstime: this.time[1],
        timeType: this.changeSelect
      }
      let res = await this.$http.post('/memberVisitDayStatistics/newuserstrend', data)
      if (res.data.code == 200) {
        let data = res.data.data
        this.dataList = data
        this.renderChart();
      }
    },
    changeTime(value) {
      this.changeSelect = value
      this.time = []
      this.getNewuserstrend()
    },
    renderChart() {
      const chart = echarts.init(this.$refs.chart);
      let seriesList = this.dataList.map(item => {
        return item.count
      })
      let xAxisList = this.dataList.map(item => {
        return item.createDate
      })
      const option = {
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["分享/单即分享/全部", "搜索/手机搜索/全部"],
        //   left: "70%", // 将图例放在中间的左边
        //   top: "top", // 将图例放在上面
        // },
        grid: {
          right: "10%", // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: "80%", // 设置图表宽度为整个容器宽度的80%
        },
        xAxis: {
          name: '天',
          nameTextStyle: {
            padding: [10, 0, -20, -5],
          },
          type: "category",
          data: xAxisList,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: seriesList,
            itemStyle: {
              color: '#2DB7F5',
            },
          },
        ],
      };

      chart.setOption(option);
    },
    toggleSeries(selectItem) {
      //    改变class
      this.legendData.forEach((item) => {
        item.active = true;
      });
      selectItem.active = false;

      const chart = echarts.init(this.$refs.chart);
      const clonedOriginSeries = _.cloneDeep(this.originSeries);
      // 改变某一个值的可见性
      this.series.forEach((item) => {
        if (item.name === selectItem.name) {
          item.visible = false; // 取反visible属性
        }
      });
      this.series.forEach((item) => {
        if (!item.visible) {
          item.data = [];
        }
      });
      const option = chart.getOption();
      console.log({ ...option, series: this.series });
      chart.setOption({ ...option, series: this.series });
      this.series = clonedOriginSeries;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.select-container {
  display: flex;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.custom-legend {
  position: absolute;
  z-index: 111;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}

/deep/ .el-input__inner {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}
</style>