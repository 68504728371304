import { render, staticRenderFns } from "./BottomNewVistorTrend.vue?vue&type=template&id=1aa4c8c3&scoped=true"
import script from "./BottomNewVistorTrend.vue?vue&type=script&lang=js"
export * from "./BottomNewVistorTrend.vue?vue&type=script&lang=js"
import style0 from "./BottomNewVistorTrend.vue?vue&type=style&index=0&id=1aa4c8c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aa4c8c3",
  null
  
)

component.options.__file = "BottomNewVistorTrend.vue"
export default component.exports