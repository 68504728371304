<template>
  <div
    style="
      display: flex;
      margin-top: 30px;
      position: relative;
      width: 670px;
      height: 160px;
    "
  >
    <div class="custom-legend" style="margin-right: 100px">
      <div style="display: flex; margin-top: 12px; margin-bottom: 10px">
        <div class="select-title">周期筛选</div>
        <div class="select-container">
          <div class="select-option active">自然日</div>
          <!-- <div class="select-option">自然周</div>
          <div class="select-option">自然月</div> -->
        </div>
      </div>
      <div style="display: flex">
        <div class="select-title">指标筛选</div>
        <div>
          <div class="select-container1">
            <div
              class="select-option"
              :class="changeSelect == 1 ? 'active' : ''"
              @click="changeTime('1')"
            >
              访问人数
            </div>
            <div
              class="select-option"
              :class="changeSelect == 2 ? 'active' : ''"
              @click="changeTime('2')"
            >
              打开次数
            </div>
            <div
              class="select-option"
              :class="changeSelect == 3 ? 'active' : ''"
              @click="changeTime('3')"
            >
              访问页面数
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="display: flex">
        <div class="select-title">指标筛选</div>
        <div>
          <div class="legend-item" v-for="item in legendData" :key="item.name" @click="onChange(item)"
            :class="{ active: item.active }">
            <div class="legend-icon" :style="{ backgroundColor: item.color }"></div>
            <div class="legend-text">{{ item.name }}</div>
          </div>
        </div>
      </div> -->
    </div>
    <div
      ref="chart"
      id=""
      style="
        width: 450px;
        height: 210px;
        margin-left: 160px;
        position: absolute;
        top: -66px;
      "
    ></div>
    <div style="margin-left: 600px">
      <div class="select-title">均值</div>
      <div style="margin-top: 10px">{{ average }}</div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { color } from "echarts/lib/export";
import _ from "lodash";
export default {
  data() {
    return {
      changeNum: "1",
      changeSelect: "1",
      average: "",
      legendData: [
        { name: "访问人数", color: "#2DB7F5", active: true },
        { name: "打开次数", color: "#808BC6", active: true },
        { name: "访问页面数", color: "#F9BF00", active: true },
      ],
      originSeries: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
      series: [
        {
          name: "访问人数",
          type: "line",
          data: [120, 200, 150, 80, 70, 110, 130],
          itemStyle: {
            color: "#2DB7F5",
          },
          visible: true,
        },
        {
          name: "打开次数",
          type: "line",
          data: [220, 180, 140, 100, 90, 120, 150],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
        {
          name: "访问页面数",
          type: "line",
          data: [320, 280, 240, 200, 190, 220, 250],
          itemStyle: {
            color: "#808BC6",
          },
          visible: true,
        },
      ],
      time: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    init(time) {
      this.time = time;
      this.getData();
    },
    changeTime(value) {
      this.changeNum = value;
      this.getData();
    },
    async getData() {
      let data = {
        startstime: this.time[0],
        endstime: this.time[1],
        accessType: this.changeNum,
      };
      let res = await this.$http.post(
        "/memberVisitDayStatistics/accessIndicators",
        data
      );
      console.log(res, 148);

      if (res.data.code == 200) {
        if (res.data.data.resultList) {
          this.dataList = res.data.data.resultList;
          this.average = res.data.data.average;
          this.renderChart();
        }
      }
    },
    // async getAccessIndicators() {
    //   let res = await this.$http.post('/memberVisitDayStatistics/accessIndicators', data)
    //   if (res.data.code == 200) {
    //     let data = res.data.data
    //     this.dataList = data.resultList.map(item => {
    //       item.rateNumber = Number(item.rate.split('').slice(0, -1).join(''))
    //       return item
    //     })
    //   }
    // },
    renderChart() {
      let xAxisList = this.dataList.map((item) => {
        return item.day;
      });
      let seriesList = this.dataList.map((item) => {
        return item.count;
      });
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false, // 隐藏原有图例
          data: ["访问人数", "打开次数", "访问页面数"],
          orient: "vertical", // 设置图例垂直排列
          left: "left", // 将图例放在中间的左边
          top: "center", // 将图例放在上面
        },
        grid: {
          left: "5%", // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: "85%", // 设置图表宽度为整个容器宽度的80%
        },

        xAxis: {
          type: "category",
          data: xAxisList,
          name: "时",
          nameTextStyle: {
            padding: [10, 0, -20, -10],
          },
        },
        yAxis: {
          name:
            this.changeNum == 1
              ? "人"
              : this.changeNum == 2
              ? "次"
              : this.changeNum == 3
              ? "面"
              : "",
          nameTextStyle: {
            padding: [10, 20, -5, 0],
          },
          type: "value",
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: seriesList,
            itemStyle: {
              color: "#2DB7F5",
            },
          },
        ],
      };

      chart.setOption(option);
    },
    onChange() {
      console.log("999999999");
    },
    toggleSeries(selectItem) {
      const chart = echarts.init(this.$refs.chart);
      const clonedOriginSeries = _.cloneDeep(this.originSeries);
      //    改变active的class
      selectItem.active = !selectItem.active;
      // 改变某一个值的可见性
      this.series.forEach((item) => {
        if (item.name === selectItem.name) {
          item.visible = !item.visible; // 取反visible属性
        }
      });
      // 让visble为false的data1为空，否则为初始值
      this.series.forEach((item) => {
        if (!item.visible) {
          item.data = [];
        } else {
          clonedOriginSeries.forEach((clonedItem) => {
            if (clonedItem.name === item.name) {
              item.data = clonedItem.data;
            }
          });
        }
      });
      const option = chart.getOption();
      console.log({ ...option, series: this.series });
      chart.setOption({ ...option, series: this.series });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.select-container {
  display: flex;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.select-container1 {
  // display: flex;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    margin-top: 2px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 26px;
    line-height: 26px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.custom-legend {
  position: absolute;
  z-index: 111;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}
</style>
