<template>
  <div>
    <div style="display: flex; margin-top: 12px;margin-bottom:15px;">
      <div class="select-title">周期筛选</div>
      <div class="select-container">
        <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
        <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
        <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
      </div>
    </div>
    <div style="display: flex; margin-bottom: 20px;">
      <div class="select-title">指标筛选</div>
      <div class="select-container">
        <div class="select-option" :class="changeNum == 1 ? 'active' : ''" @click="changeNumer('1')">访问人数</div>
        <div class="select-option" :class="changeNum == 2 ? 'active' : ''" @click="changeNumer('2')">打开次数</div>
      </div>
    </div>
    <div style="height: 180px;">
      <div v-for="item, index in dataList" :key="index">
        <div class="progress-container">
          <div class="progress-title">{{ item.visitSource }}</div>
          <div class="progress-content">{{ item.rate + '|' + item.count }}</div>
        </div>
        <el-progress :percentage="item.rateNumber" :show-text="false"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeSelect: '3',
      changeNum: '1',
      dataList: []
    }
  },
  mounted() {
    this.getAllData()
  },
  methods: {
    changeTime(value) {
      this.changeSelect = value
      this.getAllData()
    },
    changeNumer(value) {
      this.changeNum = value
      this.getAllData()
    },
    async getAllData() {
      let data = {
        timeType: this.changeSelect,
        accessType: this.changeNum
      }
      let res = await this.$http.post('/memberVisitDayStatistics/sourceTop10', data)
      if (res.data.code == 200) {
        let data = res.data.data || []
        let resultList = data.resultList || []
        this.dataList = resultList.map(item => {
          item.rateNumber = Number(item.rate.split('').slice(0, -1).join(''))
          return item
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
}

.select-container {
  display: flex;
  font-size: 12px;
  margin-left: 10px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.progress-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  .progress-title {
    margin-top: 10px;
  }

  .progress-content {
    color: #c0c0c0;
    margin: 7px 0 7px;
  }
}
</style>
