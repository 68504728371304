<template>
  <div ref="scrollContainer" style="
      width: 100%;
      height: 100vh;
      background: #f7f7f7;
      padding: 20px 0px 0 10px;
      display: flex;
      overflow: auto;
      user-select: none;
    " @mousedown="startDrag">
    <div class="left" style="margin-right: -40px">
      <!-- 左上 -->
      <div style="width: 750px; background: #fff">
        <div class="titleContainer">
          <div class="title">核心</div>
          <div style="
              color: #2697ff;
              font-size: 14px;
              font-weight: 500;
              margin-top: 3px;
            ">
            <!-- 2024/02/11-2024/05/10 -->
          </div>
        </div>
        <div class="content">
          <div style="display: flex">
            <div class="border">
              <div class="content_title">
                <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                <div>访问数据</div>
              </div>
              <div class="content_time">数据时间{{ formattedDate }}</div>
              <div class="select-all" style="display: flex; margin-top: 12px; margin-bottom: 10px">
                <div class="select-title">周期筛选</div>
                <div class="select-container">
                  <div class="select-option" :class="todaySelectTime == 3 ? 'active' : ''" @click="changeTime('3')">自然月
                  </div>
                  <div class="select-option" :class="todaySelectTime == 2 ? 'active' : ''" @click="changeTime('2')">自然周
                  </div>
                  <div class="select-option" :class="todaySelectTime == 1 ? 'active' : ''" @click="changeTime('1')">自然日
                  </div>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px">
                <div class="record">
                  <div>访问人数</div>
                  <div class="record_count">{{ accessTodayData.visitNum }}</div>
                  <!-- <div class="record_range"
                    :style="{ color: parseFloat(accessTodayData.visitNumRate) >= 0 ? '#71ed7d' : 'red' }">{{
                      accessTodayData.visitNumRate }}</div> -->
                </div>
                <div class="record">
                  <div>打开次数</div>
                  <div class="record_count">{{ accessTodayData.newUserVisitCount }}</div>
                  <!-- <div class="record_range"
                    :style="{ color: parseFloat(accessTodayData.visitCountRate) >= 0 ? '#71ed7d' : 'red' }">{{
                      accessTodayData.visitCountRate }}</div> -->
                </div>
                <div class="record" style="margin-right: 0px;width: 80px;">
                  <div>访问页面数</div>
                  <div class="record_count">{{ accessTodayData.visitPageCount }}</div>
                  <!-- <div class="record_range"
                    :style="{ color: parseFloat(accessTodayData.visitPageCountRate) >= 0 ? '#71ed7d' : 'red' }">{{
                      accessTodayData.visitPageCountRate }}</div> -->
                </div>
              </div>
            </div>

            <div class="border" style="margin-left: 5px; margin-right: 0px">
              <div class="content_title">
                <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                <div>访问实时数据</div>
              </div>
              <div class="content_time">数据时间{{ formattedDate }}</div>
              <div style="
                  display: flex;
                  margin-top: 60px;
                  position: relative;
                  width: 375px;
                  height: 140px;
                ">
                <NowDataLine ref="NowDataLineRef"></NowDataLine>
              </div>
            </div>
          </div>
          <!-- 第二行 -->
          <div style="display: flex; margin-top: 10px">
            <div style="display: flex; flex-direction: column">
              <div class="border" style="padding-bottom: 20px; padding-right: 0px; width: 395px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>活跃概况</div>
                </div>
                <div class="content_time">数据时间{{ formattedDate }}</div>
                <div style="display: flex; margin-top: 30px; flex-wrap: wrap">
                  <div class="largeRecord">
                    <div class="f16">累计用户数</div>
                    <div class="record_count f16">{{ activeSituationData.totalCount }}</div>
                    <div class="record_range f16"
                      :style="{ color: parseFloat(activeSituationData.totalCountRate) >= 0 ? '#71ed7d' : 'red' }">{{
                        activeSituationData.totalCountRate }}</div>
                  </div>
                  <div class="largeRecord">
                    <div class="f16">日访问人数</div>
                    <div class="record_count f16">{{ activeSituationData.visitNum }}</div>
                    <div class="record_range f16"
                      :style="{ color: parseFloat(activeSituationData.visitNumRate) >= 0 ? '#71ed7d' : 'red' }">{{
                        activeSituationData.visitNumRate }}</div>
                  </div>
                  <div class="largeRecord">
                    <div class="f16">日打开次数</div>
                    <div class="record_count f16">{{ activeSituationData.visitCount }}</div>
                    <div class="record_range f16"
                      :style="{ color: parseFloat(activeSituationData.visitCountRate) >= 0 ? '#71ed7d' : 'red' }">{{
                        activeSituationData.visitCountRate }}</div>
                  </div>
                  <div class="largeRecord">
                    <div class="f16">日访问页面次数</div>
                    <div class="record_count f16">{{ activeSituationData.visitPageCount }}</div>
                    <div class="record_range f16"
                      :style="{ color: parseFloat(activeSituationData.visitPageCountRate) >= 0 ? '#71ed7d' : 'red' }">{{
                        activeSituationData.visitPageCountRate }}</div>
                  </div>
                </div>
              </div>

              <div class="border" style="padding-bottom: 20px; margin-top: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                  <div>访问核心指标趋势</div>
                </div>
                <div class="content_time">数据时间{{ formattedDate }}</div>

                <div style="
                    display: flex;
                    margin-top: 30px;
                    position: relative;
                    top: 20px;
                    width: 375px;
                    height: 160px;
                  ">
                  <TrendLine></TrendLine>
                </div>
              </div>
              <div class="border" style="padding-bottom: 0px; margin-top: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                  <div>访问平台分布</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <div style="
                    display: flex;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    position: relative;
                    width: 375px;
                    height: 260px;
                  ">
                  <Platform></Platform>
                </div>
              </div>
            </div>

            <div style="margin-left: 10px; display: flex; flex-direction: column">
              <div class="border" style="padding-bottom: 30px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>活跃概况</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <div style="display: flex; margin-top: 30px">
                  <div class="record" style="width: 140px">
                    <div>新用户当日打开次数</div>
                    <div class="record_count">{{ activeSituationData.newUserVisitCount }}</div>
                    <div class="record_range"
                      :style="{ color: parseFloat(activeSituationData.newUserVisitCountRate) >= 0 ? '#71ed7d' : 'red' }">
                      {{ activeSituationData.newUserVisitCountRate }}</div>
                  </div>
                  <div class="record" style="width: 85px; margin-right: 10px">
                    <div>新用户</div>
                    <div class="record_count">{{ activeSituationData.newUserNum }}</div>
                    <div class="record_range"
                      :style="{ color: parseFloat(activeSituationData.newUserNumRate) >= 0 ? '#71ed7d' : 'red' }">{{
                        activeSituationData.newUserNumRate }}</div>
                  </div>
                </div>
              </div>
              <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>访问来源Top10</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <VisitorTrend></VisitorTrend>
              </div>
              <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>页面访问Top10</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <PageTrend></PageTrend>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 左上end -->
      <!-- 左下 -->
      <div style="
          width: 750px;
          margin-right: 50px;
          background: #fff;
          margin-top: 15px;
        ">
        <div class="titleContainer">
          <div class="title">访问</div>
          <div style="
              color: #2697ff;
              font-size: 14px;
              font-weight: 500;
              margin-top: 3px;
            ">
            <el-date-picker v-model="allTime" style="width: 260px;" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期" :clearable="false" @change="changeAllTime">
            </el-date-picker>
            <!-- 2024/02/11-2024/05/10 -->
          </div>
        </div>
        <div class="content">
          <div style="display: flex">
            <div class="border" style="margin-left: 15px; margin-right: 0px">
              <div class="content_title">
                <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                <div>访问指标趋势</div>
              </div>
              <div class="content_time">数据时间{{ formattedDate }}</div>

              <BottomVistorTrend ref="BottomVistorTrendRef"></BottomVistorTrend>
            </div>
          </div>
          <!-- 第二行 -->
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <div class="border" style="margin-left: 15px; margin-right: 0px; margin-bottom: 10px;">
              <div class="content_title">
                <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                <div>新用户引流来源趋势</div>
              </div>
              <div class="content_time">2024/05/11 16:55</div>
              <BottomNewVistorTrend ref="BottomNewVistorTrendRef"></BottomNewVistorTrend>
            </div>
          </div>
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <div class="border" style="margin-left: 15px; margin-right: 0px; margin-bottom: 10px;">
              <div class="content_title">
                <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                <div>发布市集统计</div>
              </div>
              <div class="content_time">2024/05/11 16:55</div>
              <publishMarket ref="BottomNewVistorTrendRef"></publishMarket>
            </div>
          </div>
        </div>
      </div>
      <!-- 左下end -->
    </div>
    <div class="right">
      <!-- 右上 -->
      <div>
        <div style="margin-right: 50px; padding-bottom: 10px; background: #fff">
          <div class="titleContainer">
            <div class="title">实时</div>
            <div style="
                color: #2697ff;
                font-size: 14px;
                font-weight: 500;
                margin-top: 3px;
              ">
              <!-- 2024/02/11-2024/05/10 -->
            </div>
          </div>
          <div class="content">
            <div style="display: flex">
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-right: 10px;
                ">
                <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                  <div class="content_title">
                    <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                    <div>页面实时访问明细</div>
                  </div>
                  <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                  <RightNowVistor></RightNowVistor>
                </div>
                <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                  <div class="content_title">
                    <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                    <div>场景实时访问明细</div>
                  </div>
                  <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                  <RightNowVistor2></RightNowVistor2>
                </div>
              </div>
              <div>
                <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                  <div class="content_title">
                    <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                    <div>页面实时访问趋势</div>
                  </div>
                  <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>

                  <RightNowPageVistorTrend></RightNowPageVistorTrend>
                </div>
                <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                  <div class="content_title">
                    <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                    <div>用户实时页面访问Top10</div>
                  </div>
                  <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                  <RightNowPageTopVistor></RightNowPageTopVistor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右上end -->
      <!-- 右下 -->
      <div>
        <div style="
            margin-top: 10px;
            margin-right: 50px;
            padding-bottom: 10px;
            background: #fff;
          ">
          <div class="titleContainer">
            <div class="title">画像</div>
            <div style="
                color: #2697ff;
                font-size: 14px;
                font-weight: 500;
                margin-top: 3px;
              ">
              <!-- 2024/02/11-2024/05/10 -->
            </div>
          </div>
          <div class="content">
            <div style="display: flex; margin-top: 10px">
              <div class="border" style="padding-bottom: 0px; ">

                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                  <div>用户性别分布</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>

                <UserSexTrend></UserSexTrend>

              </div>


              <div class="border" style="margin-left: 15px; margin-right: 0px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data2.svg" alt="" />
                  <div>用户年龄趋势</div>
                </div>
                <div class="content_time">2024/05/11 16:55</div>

                <UserAgeTrend></UserAgeTrend>
              </div>
            </div>
          </div>
          <div class="content">

            <div style="display: flex;">
              <div class="border" style="margin-top: 10px; padding-bottom: 10px;margin-right: 20px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>用户年龄分布</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <UserAgedistribution></UserAgedistribution>
              </div>
              <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>ios平台 Top10机型</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <IosTop></IosTop>
              </div>
            </div>
          </div>
          <div class="content">
            <!-- woring -->
            <div style="display: flex;">
              <div class="border" style="margin-top: 10px; padding-bottom: 10px;margin-right: 20px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                  <div>地级地域分布</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <AreaTrend></AreaTrend>
              </div>
              <div class="border" style="margin-top: 10px; padding-bottom: 10px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/message.png" alt="" />
                  <div>Android平台 Top10机型</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <AndroidTop></AndroidTop>
              </div>
            </div>
          </div>
          <div class="content">
            <!-- woring -->
            <div style="display: flex;">
              <div class="border" style="margin-top: 10px; padding-bottom: 10px;margin-right: 20px">
                <div class="content_title">
                  <img class="content_img" src="@/assets/images/accessAnalysis/data.png" alt="" />
                  <div>发布短视频统计</div>
                </div>
                <div class="content_time">{{ dayName + '&nbsp' + formattedDateDay }}</div>
                <publishShortVideos></publishShortVideos>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- 右下end -->
    </div>
  </div>
</template>

<script>
import NowDataLine from "./components/NowDataLine.vue";
import TrendLine from "./components/trendLine.vue";
import VisitorTrend from "./components/VisitorTrend.vue";
import PageTrend from "./components/PageTrend.vue";
import Platform from "./components/Platform.vue";
import BottomVistorTrend from "./components/BottomVistorTrend.vue";
import RightNowVistor from "./components/RightNowVistor.vue";
import RightNowVistor2 from "./components/RightNowVistor2.vue";
import BottomNewVistorTrend from "./components/BottomNewVistorTrend.vue";
import RightNowPageVistorTrend from "./components/RightNowPageVistorTrend.vue";
import RightNowPageTopVistor from "./components/RightNowPageTopVistor.vue";
import UserSexTrend from "./components/UserSexTrend.vue";
import UserAgeTrend from './components/UserAgeTrend.vue';
import UserAgedistribution from './components/UserAgedistribution.vue';
import IosTop from './components/IosTop.vue'
import AndroidTop from './components/AndroidTop.vue'
import AreaTrend from './components/AreaTrend.vue'
import publishMarket from './components/publishMarket.vue'
import publishShortVideos from './components/publishShortVideos.vue'
import { create } from "js-md5";
export default {
  components: {
    NowDataLine,
    TrendLine,
    VisitorTrend,
    PageTrend,
    Platform,
    BottomVistorTrend,
    RightNowVistor,
    RightNowVistor2,
    BottomNewVistorTrend,
    RightNowPageVistorTrend,
    RightNowPageTopVistor,
    UserSexTrend,
    UserAgeTrend,
    UserAgedistribution,
    IosTop,
    AndroidTop,
    AreaTrend,
    publishMarket,
    publishShortVideos
  },
  data() {
    return {
      allTime: {},
      todayVisitData: {},
      yesterdayVisitData: {},
      accessTodayData: {}, // 今日访问数据
      accessRealData: {}, // 访问实时数据
      activeSituationData: {}, // 活跃概况
      // activeSituationRightData: {}, // 右边边活跃概况
      accessIndicatorTrendsData: {}, // 访问核心指标趋势
      accessSourceTop10Data: {}, // 访问来源Top10
      accessPlatformDistributionData: {}, // 访问平台分布
      accessPageTop10Data: {}, // 页面访问Top10
      accessIndexTrendsData: {}, // 访问指标趋势
      NewUserflowSourceTrendsData: {}, // 新用户引流来源趋势
      formattedDate: '', // 当前时间
      formattedDateDay: '', // 获取当前时间（到天）
      dayName: '', // 获取当前周几
      todaySelectTime: '3' // 今日访问数据需要传的月，周，日
    }
  },
  created() {
    this.getAllData()
    this.getActiveOverview()
    this.getTime()
    this.getDay()
  },
  mounted() {
  },
  methods: {
    // async getData() {
    //   let res = await this.$http.post('memberVisitDayStatistics/todayVisitData', {})

    //   let res2 = await this.$http.post('memberVisitDayStatistics/yesterdayVisitData', {})

    //   if (res.data.code == 200) {
    //     this.todayVisitData = res.data.data
    //   }
    //   if (res2.data.code == 200) {
    //     this.yesterdayVisitData = res2.data.data
    //   }
    // },
    getTime() {
      const now = new Date();
      const formatter = new Intl.DateTimeFormat('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const formatterDay = new Intl.DateTimeFormat('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
      this.formattedDate = formatter.format(now);
      this.formattedDateDay = formatterDay.format(now);
    },
    getDay() {
      const now = new Date();
      const dayOfWeek = now.getDay();
      // 将数字转换为星期几的英文名称
      const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      this.dayName = days[dayOfWeek];
      console.log(this.dayName);
    },
    async getAllData() {
      let data = {}
      data.timeType = this.todaySelectTime
      let res = await this.$http.post('memberVisitDayStatistics/todayVisitData', data)
      if (res.data.code == 200) {
        // let data = res.data.data
        this.accessTodayData = res.data.data
        console.log(this.accessTodayData, 'accessTodayDataaaaa')
        // this.$refs.NowDataLineRef.init(data[1].data)
      }
    },
    // 活跃概况
    async getActiveOverview() {
      let res = await this.$http.post('memberVisitDayStatistics/activeOverview', {})
      if (res.data.code == 200) {
        this.activeSituationData = res.data.data
      }
    },
    changeTime(value) {
      this.todaySelectTime = value
      this.getAllData()
    },
    changeAllTime() {
      this.$refs.BottomVistorTrendRef.init(this.allTime)
      this.$refs.BottomNewVistorTrendRef.init(this.allTime)
    },
    // async getAllData() {
    //   let res=await this.$http.post('memberVisitDayStatistics/accessRealtimeData',{})
    //   console.log(res)
    // },
    getCurrentTime() {
      const days = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const dayOfWeek = days[date.getDay()];
      return `${dayOfWeek}${year}/${month}/${day}`;
    },
    startDrag(e) {
      // 记录鼠标按下时的位置和滚动位置
      this.startX = e.pageX - this.$refs.scrollContainer.offsetLeft;
      this.startY = e.pageY - this.$refs.scrollContainer.offsetTop;
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
      this.scrollTop = this.$refs.scrollContainer.scrollTop;

      // 添加鼠标移动事件监听
      document.addEventListener("mousemove", this.moveScroll);
      // 添加鼠标松开事件监听
      document.addEventListener("mouseup", this.stopDrag);
    },
    moveScroll(e) {
      // 计算鼠标移动的距离
      const x = e.pageX - this.$refs.scrollContainer.offsetLeft;
      const y = e.pageY - this.$refs.scrollContainer.offsetTop;

      // 计算滚动的距离
      const scrollX = x - this.startX;
      const scrollY = y - this.startY;

      // 更新滚动容器的滚动位置
      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - scrollX;
      this.$refs.scrollContainer.scrollTop = this.scrollTop - scrollY;
    },
    stopDrag() {
      // 移除鼠标移动事件监听
      document.removeEventListener("mousemove", this.moveScroll);
      // 移除鼠标松开事件监听
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
};
</script>

<style lang="scss" scoped>
.titleContainer {
  display: flex;
  padding: 10px 15px 15px 20px;
  border-bottom: 1px solid #ededed;
  justify-content: space-between;

  .title {
    font-weight: 700;
  }
}

.content {
  padding: 10px 10px 0;

  .content_title {
    display: flex;
    font-weight: 550;

    .content_img {
      width: 20px;
      margin-right: 5px;
    }
  }

  .content_time {
    color: #c0c0c0;
    margin-left: 15px;
    margin-top: 3px;
    font-size: 12px;
  }
}

.record {
  margin-right: 20px;
  width: 70px;
  font-size: 15px;

  .record_count {
    font-weight: 600;
    margin-top: 10px;
  }

  .record_range {
    margin-top: 10px;
    font-size: 12px;
    color: #ee9a9a;
  }

  .record_range_blue {
    margin-top: 10px;
    font-size: 12px;
    color: #71ed7d;
  }
}

.largeRecord {
  margin-top: 10px;
  margin-right: 20px;
  width: 160px;

  .record_count {
    font-weight: 600;
    margin-top: 10px;
  }

  .record_range {
    margin-top: 10px;
    font-size: 12px;
    color: #71ed7d;
  }
}

.border {
  border: 1px solid #ededed;
  padding: 20px 20px 0 20px;
}

::v-deep .el-input__inner {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}

::v-deep .el-range-input {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}

/* 今日访问数据自然日月周 */
.select-all {
  .select-title {
    color: #c0c0c0;
    font-size: 12px;
    line-height: 20px;
  }

  .select-container {
    display: flex;
    font-size: 12px;
    margin-left: 10px;

    .select-option {
      border: 1px solid #c0c0c0;
      border-radius: 5px;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      margin-right: 5px;
      text-align: center;

      &:hover,
      &.active {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }
  }
}

/* 滚动条 */
::-webkit-scrollbar {
  width: 8px !important;
  /* 滚动条宽度 */
  background-color: #f7f7f7 !important;
  /* 滚动条背景色 */
}

/* 滑块 */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4 !important;
  /* 滑块颜色 */
  border-radius: 4px !important;
  /* 滑块圆角 */
}

/* 鼠标悬停时的滑块 */
::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8 !important;
  /* 悬停时的滑块颜色 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #f7f7f7 !important;
  /* 滚动条轨道颜色 */
}

/* 滚动条轨道上的边距 */
::-webkit-scrollbar-track-piece {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/* 拖动滑块时的效果 */
::-webkit-scrollbar-thumb:active {
  background-color: #878787 !important;
  /* 拖动时的滑块颜色 */
}
</style>
