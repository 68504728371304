<template>
  <div>
    <div style="display: flex;">
      <div style="width: 100px;">
        <div class="select-title">指标筛选</div>
        <el-select v-model="selectedOption" placeholder="请选择" @change="onChange"
          style="width: 95px; height: 10px; outline: none; z-index: 999;">
          <el-option label="用户人数" value="1"></el-option>
          <el-option label="打开次数" value="2"></el-option>
          <el-option label="访问页面数" value="3"></el-option>
        </el-select>
        <div style="margin-top: 10px;" class="select-title">均值</div>
        <div>{{ average }}</div>
      </div>
      <div ref="chart" style="width:280px; height: 250px;position: absolute;top: -66px;left: 100px"></div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';

export default {
  data() {
    return {
      selectedOption: '1',
      dataList: {},
      average: '',
    }
  },
  mounted() {
    // this.renderChart();
    this.getAccessIndicators('1')
    this.average = '1'
  },
  methods: {
    init() {
      this.getAccessIndicators('1')
    },
    onChange() {
      this.getAccessIndicators(this.selectedOption)
    },
    async getAccessIndicators(accessType) {
      let res = await this.$http.post('/memberVisitDayStatistics/accessIndicators', { accessType })
      if (res.data.code == 200) {
        let data = res.data.data
        this.dataList = data.resultList
        this.average = data.average
        this.renderChart()
      }
    },
    renderChart() {
      let xAxisList = this.dataList.map(item => {
        return item.day
      })
      let seriesList = this.dataList.map(item => {
        return item.count
      })
      const chart = echarts.init(this.$refs.chart);

      const option = {
        //   title: {
        //     text: '访问统计',
        //   },
        tooltip: {
          trigger: 'axis',
        },
        // legend: {
        //   data: ['访问人数', '打开次数', '访问页面数'],
        //   orient: 'vertical', // 设置图例垂直排列
        //   left: 'left', // 将图例放在中间的左边
        //   top: 'center', // 将图例放在上面
        // },
        grid: {
          left: '10%', // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: '80%', // 设置图表宽度为整个容器宽度的80%

        },
        xAxis: {
          name: '时',
          nameTextStyle: {
            padding: [10, 0, -20, -10],
          },
          type: 'category',
          data: xAxisList,
        },
        yAxis: {
          name: this.selectedOption == 1 ? '人' : this.selectedOption == 2 ? '次' : this.selectedOption == 3 ? '面' : '',
          nameTextStyle: {
            padding: [10, 20, -5, 0],
          },
          type: 'value',
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '访问人数',
            type: 'line',
            data: seriesList,
            itemStyle: {
              color: '#2DB7F5',
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}
/deep/ .el-input__inner {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}
</style>