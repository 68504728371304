<template>
  <div style="display: flex; margin-left: 10px;
                    margin-top: 30px;
                    position: relative;
                    width: 610px;
                    height: 400px;
                  ">
    <div class="custom-legend" style="margin-right: 100px">
      <!-- <div>
        <div class="select-title">指标筛选</div>
        <el-select @change="changeTime" v-model="minute" placeholder="请选择" style="width: 70px; outline: none">
          <el-option label="3" value="5"></el-option>
          <el-option label="2" value="30"></el-option>
          <el-option label="1" value="60"></el-option>
        </el-select>
      </div> -->
      <div style="margin-top: 12px;margin-bottom:15px;width: 400px;">
        <div class="select-title">周期筛选</div>
        <div class="select-container1">
          <div class="select-option" :class="timeType == 3 ? 'active' : ''" @click="changeTime('3')">自然月</div>
          <div class="select-option" :class="timeType == 2 ? 'active' : ''" @click="changeTime('2')">自然周</div>
          <div class="select-option" :class="timeType == 1 ? 'active' : ''" @click="changeTime('1')">自然日</div>
        </div>
      </div>
      <!-- <div style="">
        <div class="select-title">指标筛选</div>
        <el-select v-model="selectedOption" placeholder="请选择" style="width: 70px; outline: none">
          <el-option label="分享/单即分享/全部" value="share"></el-option>
          <el-option label="搜索/手机搜索/全部" value="singleShare"></el-option>
        </el-select>
        <div>

          <div
                class="legend-item"
                v-for="item in legendData"
                :key="item.name"
                @click="toggleSeries(item)"
                :class="{ 'active': item.active }"
              >
                <div
                  class="legend-icon"
                  :style="{ backgroundColor: item.color }"
                ></div>
                <div class="legend-text">{{ item.name }}</div>
              </div>
        </div>
      </div> -->
      <div>
        <div class="select-title">指标筛选</div>
        <div>
          <div class="select-container1">
            <div class="select-option" :class="changeSelect == 1 ? 'active' : ''" @click="changeNumer('1')">访问人数
            </div>
            <div class="select-option" :class="changeSelect == 2 ? 'active' : ''" @click="changeNumer('2')">打开次数
            </div>
            <div class="select-option" :class="changeSelect == 3 ? 'active' : ''" @click="changeNumer('3')">访问页面数
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="chart" style="width: 620px; height: 400px; margin-left: 10px; margin-top: -60px"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import _ from "lodash";
export default {
  data() {
    return {
      minute: '5',
      timeType: '3',
      selectedOption: "",
      changeSelect: '1',
      tableList: [],
      legendData: [
        { name: "访问人数", color: "#2DB7F5", active: true },
        { name: "打开次数", color: "#808BC6", active: true },
        { name: "访问页面数", color: "#F9BF00", active: true },
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let data = {
        timeType: this.timeType,
        accessType: this.changeSelect,
      }
      let res = await this.$http.post('/memberVisitDayStatistics/pageAccessTrend', data)
      if (res.data.code == 200) {
        this.tableList = res.data.data
        this.renderChart();
      }
    },
    changeTime(value) {
      this.timeType = value
      this.getData()
    },
    changeNumer(value) {
      this.changeSelect = value
      this.getData()
    },

    renderChart() {
      let xAxisList = this.tableList.map(item => {
        return item.intime
      })
      let yAxisList = this.tableList.map(item => {
        return item.count
      })
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["分享/单即分享/全部", "搜索/手机搜索/全部"],
        //   left: "70%", // 将图例放在中间的左边
        //   top: "top", // 将图例放在上面
        // },
        grid: {
          left: "30%", // 将图表整体向右偏移 20%，实现标注在中间左边的效果
          width: "60%", // 设置图表宽度为整个容器宽度的80%
        },
        xAxis: {
          name: '天',
          nameTextStyle: {
            padding: [10, 0, -16, 10],
          },
          type: "category",
          data: xAxisList,
        },
        yAxis: {

          type: "value",
        },
        series: [
          {
            // name: "分享/单即分享/全部",
            type: "line",
            smooth: true,
            data: yAxisList,
            lineStyle: {
              color: "#2DB7F5",
            },
            itemStyle: {
              color: "#2DB7F5",
            },
            areaStyle: {
              color: "#D5F0FD",
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-title {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.select-container1 {
  // display: flex;
  font-size: 12px;
  // margin-left: 10px;
  width: 80px;

  .select-option {
    margin-top: 2px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 26px;
    line-height: 26px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.select-container {
  font-size: 12px;
  // margin-left: 10px;
  width: 80px;

  .select-option {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;

    &:hover,
    &.active {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.custom-legend {
  position: absolute;
  z-index: 111;
}

.legend-item {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px 5px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  text-align: center;
  background-color: #fff;

  &.active {
    background-color: #f0f0f0;
    cursor: pointer;
  }
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}

/deep/ .el-input__inner {
  background: #e9e9e9;
  border-color: #c0c0c0 !important;
  outline: #c0c0c0 !important;
  font-size: 12px;
}
</style>
